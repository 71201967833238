import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'react-auth-kit';
import refreshUser from './components/RefreshUser';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

const queryClient = new QueryClient();
ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider
            authName="_auth"
            authType="cookie"
            cookieDomain={window.location.hostname}
            refresh={refreshUser}
          >
            <LocalizationProvider
              adapterLocale="en"
              dateAdapter={AdapterDayjs}
              localeText={{ start: 'Date de debut', end: 'Date de fin' }}
            >
              <App />
            </LocalizationProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
