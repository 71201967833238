import axios from 'axios';
import { createRefresh } from 'react-auth-kit';
import { BASEURL } from '../Constants';

const refreshUser = createRefresh({
  interval: 1440,
  refreshApiCallback: async ({ refreshToken, authUserState }) => {
    const options = {
      method: 'POST',
      url: `${BASEURL}/dashboard/refresh/`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        refresh: refreshToken
      }
    };
    const response = await axios
      .request(options)
      .then(({ data }) => {
        return {
          isSuccess: true,
          newAuthToken: data.access,
          newRefreshToken: data.refresh,
          newRefreshTokenExpiresIn: 4320,
          newAuthUserState: {
            username: data.username,
            email: data.email,
            lastname: data.first_name,
            firstname: data.last_name,
            roles: data.roles
          }
        };
      })
      .catch();

    return response;
  }
});

export default refreshUser;
